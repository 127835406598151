import React from "react"
import { ThemeProvider, createGlobalStyle } from "styled-components"

const colors = {
  brightRed: "#FC3250",
  brightBlue: "#167FFC",
  darkBlueText: "#1F2040",
  white: "#ffffff",
  darkBlueBorder: "#141626",
  lightGrey: "#0000001A",
  mediumGrey: "#707070",
  darkGrey: "#303030",
  black: "#1D1D1D",
  darkBlue: "#0C0D17",
  lightBlue: "#282c4c",
}

export const windowWidths = {
  desktopHD: 1920,
  desktopStandard: 1366,
  desktopSmall: 1240,
  tabletLarge: 960,
  tabletSmall: 768,
  mobileLarge: 480,
  mobileStandard: 375,
}

const theme = {
  ...colors,
  desktopHD: `${windowWidths.desktopHD}px`,
  desktopSmall: `${windowWidths.desktopSmall}px`,
  desktopStandard: `${windowWidths.desktopStandard}px`,
  tabletLarge: `${windowWidths.tabletLarge}px`,
  tabletSmall: `${windowWidths.tabletSmall}px`,
  mobileLarge: `${windowWidths.mobileLarge}px`,
  mobileStandard: `${windowWidths.mobileStandard}px`,
}

const GlobalStyle = createGlobalStyle``

const ThemeConfig = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      {children}
    </ThemeProvider>
  )
}

export default ThemeConfig
